import React from 'react'
import './Project.css'

const Project = () => {
  return (
    <div className="project">
        <h1>This page is under Development</h1>
    </div>
  )
}

export default Project
